import ReactGA from 'react-ga4';


export enum AnalyticsActions {
    btn_click = "Button Click",
    tab_open = "Tab Open",
    modal_open = "Modal Open",
    ticker_click = "Ticker Click"

}
export enum AnalyticsCategories {
    alerts = "Alerts",
    heaters = "Sector Heaters",
    longterm = "Long Term",
    calendar = "Calendar",
    wrap = "Daily Wrap",
    content = "Content",
    settings = "Settings",
    help_menu = "Help Menu",
    auth = "Authentication",
    dashboard = "Dashboard"
}



export class AnalyticsHelper {
    private static isInitialized = false;

    static initialize() {
        if (process.env.REACT_APP_STAGE === 'prod' && !this.isInitialized) {
            ReactGA.initialize(process.env.REACT_APP_PUBLIC_ANALYTICS || '');
            this.isInitialized = true;
        }
    }

    static logPageView(url: string) {
        this.initialize();
        if (this.isInitialized) {
            ReactGA.send({ hitType: 'pageview', page: url });
        }
    }

    static logEvent(action: AnalyticsActions, category: AnalyticsCategories, label: string, value?: number) {
        this.initialize();
        if (this.isInitialized) {
            ReactGA.event({
                action,
                category,
                label,
                value,
            });
        }
    }
}

