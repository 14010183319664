import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import KnowledgeBtn from '../../components/controls/knowledge-btn'
import { StyleHelper } from '../../components/style-helper'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'
import { useEffect, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
import LoadingButton from '../../components/controls/loading-btn'
import { Close, DateRange, FilterAlt } from '@mui/icons-material'
import { WrapService } from '../../services/wrap.service'
import { useError } from '../../context/error'
import EventCardLoading from '../../components/loading/event-card.loading'
import WrapCard from '../../components/wrapcard'
import { useNavigate } from 'react-router-dom'

interface WrapPageParams {
  startDate?: string
}

export default function WrapPage(props: PageProps) {
  useDocumentTitle(props.title)
  const { setError } = useError()

  const [queryParams, setQueryParams] = useQueryParamsV2<WrapPageParams>()
  const [loading, setLoading] = useState(true)
  const [wraps, setWraps] = useState<DailyWrap[]>([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const theme = useTheme()
  const wrapService = new WrapService(setError)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const navigate = useNavigate()

  const [startDate, setStartDate] = useState<string>(
    queryParams.startDate ?? formatDate(yesterday)
  )

  useEffect(() => {
    _fetchWraps(1, startDate)
  }, [])

  const _fetchWraps = async (p: number, startD: string) => {
    setPage(p)
    const start = new Date(startD)
    setLoading(true)

    setQueryParams({
      startDate: startD
    })
    const results = await wrapService.searchWraps(p, start.toISOString())
    if (results.docs) {
      setWraps(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
    }
    setLoading(false)

  }

  function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box display='flex' alignItems='center' gap={2}>
        <Typography variant='h4'>Daily Wraps</Typography>
      </Box>
      <StyleHelper.VerticalSpace />
      <Grid container alignItems='center' justifyContent={'center'}>
        <Grid item lg={6}>
          <Paper
            sx={{
              border: `1px solid ${theme.palette.divider}`
            }}
          >
            <Box p={2}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Filters
              </Typography>
              <StyleHelper.VerticalSpace />
              <Grid container spacing={2} alignItems='center'>
                <Grid item lg={6}>
                  <TextField
                    label='Start Date'
                    type='date'
                    fullWidth
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item lg={6}>
                  <LoadingButton
                    variant='contained'
                    endIcon={<FilterAlt />}
                    onClick={() => _fetchWraps(1, startDate)}
                    loading={loading}
                    disabled={loading}
                  >
                    Apply Filter
                  </LoadingButton>
                  <IconButton
                    onClick={() => {
                      setStartDate(formatDate(yesterday))
                      _fetchWraps(1, formatDate(yesterday))
                    }}
                    disabled={loading}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <StyleHelper.DoubleVerticalSpace />
      <Grid container flexWrap={'wrap'} spacing={2}>
        {loading &&
          Array.from(new Array(8)).map((_, index) => (
            <Grid item lg={4} xs={12}>
              <EventCardLoading key={index} />
            </Grid>
          ))}
        {!loading &&
          wraps?.map(wrap => (
            <Grid item lg={4} xs={12} key={wrap.id}>
              <WrapCard
                wrap={wrap}
                navigateToWrap={() =>
                  navigate(`/daily-wrap/view?id=${wrap.id}`)
                }
              />
            </Grid>
          ))}
      </Grid>
      <StyleHelper.VerticalSpace />
      <Stack spacing={2} justifyContent='center' alignItems='center'>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event: unknown, page: number) => {
            _fetchWraps(page, startDate)
          }}
          variant='outlined'
          shape='rounded'
        />
      </Stack>
    </Container>
  )
}
