import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Skeleton,
  Typography
} from '@mui/material'
import PageProps from '../_types/page-props.interface'
import Sidebar from '../components/sidebar'
import VerifyEmailModal from '../components/verify-email'
import { Fragment, useEffect, useState } from 'react'
import { useGlobalState } from '../context/global'
import { useError } from '../context/error'
import { AlertService } from '../services/alert.service'
import { CarnivoreAlert } from '../_types/alert.type'
import { StyleHelper } from '../components/style-helper'
import AlertViewLoadingState from '../components/loading/alert-view.loading'
import ViewAlertModal from '../components/modals/view-alert.modal'
import useBoolean from '../hooks/useBoolean'
import AlertView from '../components/alert-view'
import { ArrowRightAlt, MenuBook } from '@mui/icons-material'
import DASHBOARD_QUICK_LINKS from '../_constants/dashboard-quicklinks'
import DashboardLinkView from '../components/dashbaord-link'
import WelcomeModal from '../components/modals/welcome.modal'
import { UserService } from '../services/user.service'
import { CarnivoreUser } from '../_types/user.type'
import { WrapService } from '../services/wrap.service'
import useGetPortfolioReturns from '../hooks/useGetPortfolioReturns'
import useDocumentTitle from '../hooks/useDocumentTitle'
import CarnivoreLogo from '../components/imgs/carnivore-logo'

function PortfolioReturn () {
  const { errorMessage, loading, returnPercentage } = useGetPortfolioReturns()

  return (
    <Fragment>
      <Typography variant='h6' pl={1}>
        Portfolio Return
      </Typography>
      {loading ? (
        <Paper elevation={3} sx={{ m: 1, p: 2 }}>
          <Skeleton variant='text' height={'7rem'} />
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ m: 1, p: 2 }}>
          {errorMessage ? (
            <Typography variant='h4' textAlign={'center'} color={'error'} >
              error
            </Typography>
          ) : (
            <Typography variant='h2' textAlign={'center'}>
              {returnPercentage}% <small>YTD</small>
            </Typography>
          )}
        </Paper>
      )}
    </Fragment>
  )
}

export default function Dashboard (page: PageProps) {
  const [showVerify, setShowVerify] = useState(false)
  const { user, setUser, setShowIntro, banner } = useGlobalState()

  const { setError } = useError()

  const alertService = new AlertService(setError)
  const userService = new UserService(setError)
  const [currentAlert, setCurrentAlert] = useState<CarnivoreAlert | null>(null)

  const [showWelcome, setShowWelcome] = useState(false)
  const [showAlertModal, { toggle: toggleShowAlertModal }] = useBoolean()
  const [loading, setLoading] = useState(true)
  useDocumentTitle(page.title)
  useEffect(() => {
    if (user && !user?.isOnboarded) {
      setShowWelcome(true)
    }
  }, [user])

  useEffect(() => {
    _fetchRecentAlert()
  }, [])

  const _fetchRecentAlert = async () => {
    const results = await alertService.searchAlerts(1, undefined, undefined, 1)
    if (results?.alerts?.docs?.length > 0) {
      setCurrentAlert(results.alerts?.docs[0])
    }
    setLoading(false)
  }

  const _finishWelcome = () => {
    const tempUser: CarnivoreUser = JSON.parse(JSON.stringify(user))
    tempUser.isOnboarded = true
    userService.updateUser({ isOnboarded: true }).then(res => {
      setUser(res.user)
    })
    setShowWelcome(false)
  }
  return (
    <Container>
      <Typography variant='h5'>Dashboard</Typography>
      <StyleHelper.DoubleVerticalSpace />
      {banner && (
        <Paper elevation={1} sx={{ mb: 3 }}>
          <Box p={3}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={4} lg={1}>
                <Box
                  width={50}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                >
                  <CarnivoreLogo />
                </Box>
              </Grid>
              <Grid item xs={8} lg={10}>
                <Typography
                  variant='subtitle2'
                  fontWeight={'bold'}
                  color={'primary'}
                >Important Message</Typography>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  {banner?.title}
                </Typography>
                <Typography variant='body1'>{banner?.msg}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} lg={8}>
          <Typography variant='h6' pl={1}>
            Quick Links
          </Typography>
          <Paper elevation={3} sx={{ m: 1, p: 2, height: '100%' }}>
            <Grid container p={3} spacing={3} flexWrap={'wrap'} height={'100%'} justifyContent={'center'}>
              {DASHBOARD_QUICK_LINKS.map((link, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <DashboardLinkView link={link} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography variant='h6' pl={1}>
            Most Recent Alert
          </Typography>
          {loading && <AlertViewLoadingState />}
          {!loading && currentAlert && (
            <AlertView
              hideIcon={true}
              alert={currentAlert}
              key={currentAlert.id}
              showAlert={toggleShowAlertModal}
            />
          )}
          <StyleHelper.VerticalSpace />
          <PortfolioReturn />
        </Grid>
      </Grid>

      <StyleHelper.DoubleVerticalSpace />

      {!loading && currentAlert && (
        <ViewAlertModal
          open={showAlertModal}
          onClose={toggleShowAlertModal}
          alert={currentAlert}
          size='lg'
        />
      )}
      <WelcomeModal open={showWelcome} size='lg' onClose={_finishWelcome} />
    </Container>
  )
}
