import { useCallback, useEffect, useRef, useState } from 'react'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { useError } from '../context/error'
import { AlertService } from '../services/alert.service'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { CarnivoreAlert } from '../_types/alert.type'
import AlertView from '../components/alert-view'
import throttle from 'lodash/throttle'
import AlertViewLoadingState from '../components/loading/alert-view.loading'
import ViewAlertModal from '../components/modals/view-alert.modal'
import AlertSearchBar, { SearchOptions } from '../components/search-bar'
import { useGlobalState } from '../context/global'
import { StyleHelper } from '../components/style-helper'
import useBoolean from '../hooks/useBoolean'
import { BookmarkSidebar } from '../components/modals/bookmarks.sidebar'
import { Bookmark } from '@mui/icons-material'

export default function AlertsPage (props: PageProps) {
  useDocumentTitle(props.title)

  const { setError } = useError()
  const [page, setPage] = useState(1)
  const [refreshing, setRefreshing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alerts, setAlerts] = useState<CarnivoreAlert[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [ticker, setTicker] = useState('')
  const [alertType, setAlertType] = useState('')
  const alertService = new AlertService(setError)
  const { addToViewAlerts, user, liveAlert } = useGlobalState()
  const [currentAlert, setCurrentAlert] = useState<CarnivoreAlert | null>(null)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [newestId, setNewestId] = useState('')
  const [showBookmarks, { toggle: toggleBookmarks }] = useBoolean(false)
  const audioRef = useRef(new Audio(require('../assets/lion-roar.mp3')))
  const [isMuted, { toggle: toggleIsMuted }] = useBoolean(true)
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const _changeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertType((event.target as HTMLInputElement).value)
    _pageAlerts(1, true, (event.target as HTMLInputElement).value, ticker)
  }

  const _pageAlerts = useCallback(
    async (
      p: number,
      clear: boolean = false,
      alertType?: string,
      ticker?: string
    ) => {
      if (!loading && !refreshing) {
        // Check to prevent multiple calls
        setLoading(true)
        if (clear) {
          setAlerts([])
        }
        setPage(p)
        setAlertType(alertType ?? '')
        setTicker(ticker ?? '')

        setRefreshing(true)
        const results = await alertService.searchAlerts(p, ticker, alertType)
        addToViewAlerts(results.viewedAlerts ?? [])
        setTimeout(() => {
          if (clear) {
            setAlerts(results.alerts?.docs ?? [])
          } else {
            setAlerts(prevAlerts => [
              ...prevAlerts,
              ...(results.alerts?.docs ?? [])
            ])
          }
          setHasNextPage(results.alerts?.hasNextPage ?? false)
          setRefreshing(false)
          setLoading(false)
        }, 500)
      }
    },
    [loading, refreshing, alertService]
  )

  useEffect(() => {
    if (liveAlert) {
      if (!alerts.map(a => a.id).includes(liveAlert.id)) {
        setAlerts(prev => [liveAlert, ...prev])

        setNewestId(liveAlert.id)

        try {
          if (!isMuted) audioRef.current.play()
        } catch (e) {}
      }
    }
  }, [liveAlert])

  const onSearch = (ticker: string, options: SearchOptions): void => {
    _pageAlerts(1, true, alertType, ticker)
    // Implementation of search functionality
    // Here, you'd call alertService.searchAlerts or similar function
  }

  useEffect(() => {
    _pageAlerts(1) // Assuming your first page is numbered as 0, adjust accordingly
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (bottomRef.current && hasNextPage && !loading) {
        const { offsetTop } = bottomRef.current
        const { scrollTop, clientHeight } = document.documentElement
        if (offsetTop <= scrollTop + clientHeight + 5) {
          _pageAlerts(page + 1, false, alertType, ticker)
        }
      }
    }

    // Throttle the scroll event to avoid performance issues
    const throttledScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)
  }, [page, hasNextPage, loading, _pageAlerts])

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='h4'>Alert Feed</Typography>
      <Grid container spacing={1}>
        <Grid
          item
          lg={2}
          sx={{
            position: { lg: 'sticky' },
            top: { lg: 0 },
            height: { lg: 'fit-content' }
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={12}>
              <Paper elevation={2} sx={{ p: 2, mt: 1 }}>
                <FormControl>
                  <FormLabel
                    id='demo-controlled-radio-buttons-group'
                    sx={{ fontWeight: 'bold' }}
                  >
                    Alert Type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby='alert-type-group'
                    name='alert-type-group'
                    value={alertType}
                    onChange={_changeFilter}
                  >
                    <FormControlLabel
                      value=''
                      control={<Radio />}
                      label='All'
                    />
                    <FormControlLabel
                      value='alert'
                      control={<Radio />}
                      label='Alerts'
                    />
                    <FormControlLabel
                      value='update'
                      control={<Radio />}
                      label='Updates'
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <Paper elevation={2} sx={{ p: 2, mt: 1 }}>
                <FormLabel sx={{ fontWeight: 'bold' }}>
                  Notification Sound
                </FormLabel>
                <Box sx={{ my: 2 }} />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!isMuted}
                        onChange={toggleIsMuted}
                        inputProps={{
                          'aria-label': !isMuted ? 'Enabled' : 'Muted'
                        }}
                      />
                    }
                    label={!isMuted ? 'Enabled' : 'Muted'}
                  />
                </FormGroup>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Paper elevation={2} sx={{ p: 2, mt: 1 }}>
                <FormLabel
                  id='demo-controlled-radio-buttons-group'
                  sx={{ fontWeight: 'bold' }}
                >
                  Bookmarks
                  {user?.bookmarkedAlerts?.length || -1 > 0
                    ? ` (${user?.bookmarkedAlerts?.length})`
                    : ''}
                </FormLabel>
                <Box sx={{ my: 2 }} />
                <Box display='flex' justifyContent='center'>
                  <Button
                    variant='contained'
                    endIcon={<Bookmark />}
                    onClick={toggleBookmarks}
                  >
                    View
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={10}>
          <AlertSearchBar onSearch={onSearch} />
          {alerts?.map(alert => (
            <AlertView
              alert={alert}
              key={alert.id}
              liveID={newestId}
              showAlert={(alert: CarnivoreAlert) => setCurrentAlert(alert)}
            />
          ))}
          {refreshing &&
            Array.from({ length: 10 }).map((_, index) => (
              <AlertViewLoadingState key={index} />
            ))}
          {currentAlert && (
            <ViewAlertModal
              open={currentAlert != null}
              onClose={() => setCurrentAlert(null)}
              alert={currentAlert}
              size='lg'
            />
          )}
          <div ref={bottomRef} />
        </Grid>
      </Grid>

      <BookmarkSidebar isOpen={showBookmarks} onClose={toggleBookmarks} />
    </Container>
  )
}
