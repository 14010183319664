import React, { useEffect, useState } from 'react'
import { ThemeProvider } from './context/theme'
import CarnivoreRouter from './CarnivoreRouter'
import Sidebar from './components/sidebar'
import TopBar from './components/topbar'
import { useGlobalState } from './context/global'
import LoadingPage from './pages/loading.page'
import { useError } from './context/error'
import { UserService } from './services/user.service'
import { useNavigate } from 'react-router-dom'
import { KnowledgeCenterModal } from './components/modals/knowledge-center.modal'
import { SettingsService } from './services/settings.service'
import TickerDrawer from './components/ticker-drawer'
import { useMediaQuery, useTheme } from '@mui/material'
import MobileNavBar from './components/mobilenav'
import { StyleHelper } from './components/style-helper'
import { BannerService } from './services/banner.service'
import axios from 'axios'
import MaintenancePage from './pages/maintenance.page'

function App () {
  const {
    isSidebarOpen,
    isAuthed,
    setUser,
    user,
    setIsAuthed,
    setSettings,
    settings,
    setBanner
  } = useGlobalState()
  const { setError } = useError()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDown, setIsDown] = useState(false)
  const userService = new UserService(setError)
  const settingsService = new SettingsService(setError)
  const bannerService = new BannerService(setError)

  const navigate = useNavigate()

  useEffect(() => {
    _checkUser()
  }, [])

  useEffect(() => {
    if (settings) {
    }
  }, [settings])

  const _checkUser = async () => {
    const healthResponse = await axios
      .get(process.env.REACT_APP_API_URL as string)
      .then(e => true)
      .catch(e => false)
    if (!healthResponse) {
      setIsDown(true)
      setIsLoading(false)
      return
    }
    const response = await userService.getUser()
    const settingsResponse = await settingsService.getSettings()

    if (settingsResponse.settings) {
      setSettings(settingsResponse.settings)
    }
    setTimeout(() => {
      if (!response.user) {
        setIsLoading(false)
        console.log('in here')
        if (!window.location.href.includes('/logout') && !window.location.href.includes('/forgot')) {
          navigate('/login')
        }
      } else {
        bannerService.getCurrentBanner().then(res => {
          setBanner(res?.banner)
        })

        setUser(response.user)
        setIsAuthed(true)
        if (!response.subscription) {
          console.log(response.user)
          if (!response.user.isOnboarded) {
            navigate('/signup/package')
          } else {
            navigate('/no-sub')
          }
          setIsLoading(false)
          return
        }
        const location = window.location.href
        if (location.includes('/login')) {
          navigate('/dashboard')
          setIsLoading(false)
        }
        setIsLoading(false)
      }
    }, 300)
  }

  return (
    <ThemeProvider>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          {isDown ? (
            <MaintenancePage />
          ) : isAuthed &&
            !window.location.href.includes('/signup') &&
            !window.location.href.includes('/no-sub') ? (
            <>
              {isMobile ? (
                <>
                  <MobileNavBar />
                  <TopBar /> {/* Pass toggleSidebar function as prop */}
                  <StyleHelper.SmallVerticalSpace />
                  <CarnivoreRouter />
                </>
              ) : (
                <>
                  <Sidebar />
                  <div style={{ marginLeft: isSidebarOpen ? 240 : 56 }}>
                    {' '}
                    {/* Adjust margin based on sidebar visibility */}
                    <TopBar /> {/* Pass toggleSidebar function as prop */}
                    <CarnivoreRouter />
                  </div>
                </>
              )}
            </>
          ) : (
            <CarnivoreRouter />
          )}
          <TickerDrawer />
          <KnowledgeCenterModal />
        </>
      )}
    </ThemeProvider>
  )
}

export default App
