import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography
} from '@mui/material'
import { DailyWrap } from '../_types/daily-wrap.type'
import { Alarm, ArrowRightAltRounded } from '@mui/icons-material'

interface WrapCardProps {
  wrap: DailyWrap
  navigateToWrap: () => void
}

export default function WrapCard(props: WrapCardProps) {
  const { wrap } = props

  function _isToday(date: string): boolean {
    const today = new Date()
    return date === formatDate(today)
  }

  function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  function formatDisplay(date: string) {
    let time
    const isoFormat = new Date(date + 'T00:00:00')
    if (!isNaN(isoFormat.getTime())) {
      time = isoFormat
    } else {
      const usFormat = new Date(date)
      if (!isNaN(usFormat.getTime())) {
        time = usFormat
      } else {
        throw new Error('Invalid date format')
      }
    }
    return time.toLocaleDateString('en-US', { dateStyle: 'medium' })
  }

  function formatDayOfWeek(date: string) {
    const day = new Date(date).toLocaleDateString('en-US', { weekday: 'long' })
    return day
  }

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: 3, position: 'relative' }}>
      <CardMedia
        component="img"
        sx={{ width: 80, height: 80, borderRadius: 1 }}
        image={require('../assets/wrap-card.jpg')}
        alt="wrap thumbnail"
      />
      <Box sx={{ ml: 2, flexGrow: 1 }}>
        <Chip
          label={formatDayOfWeek(wrap.formattedDate ?? '')}
          color="primary"
          size="small"
          sx={{ mt: 0.5, position: 'absolute', top: 8, right: 8, fontWeight: 'bold' }}
        />
      
        <Typography variant="h6" fontWeight="medium" >
          {formatDisplay(wrap.formattedDate ?? '')}
        </Typography>
        {_isToday(wrap.formattedDate ?? '') && (
          <Chip
            label="Today’s Wrap"
            icon={<Alarm />}
            color="primary"
            size="small"
            sx={{ mt: 0.5 }}
          />
        )}
        <Button
          variant="outlined"
          size="small"
          onClick={props.navigateToWrap}
          endIcon={<ArrowRightAltRounded />}
          sx={{ mt: 1, textTransform: 'none', fontWeight: 'bold' }}
        >
          Read Now
        </Button>
      </Box>
    </Card>
  )
}
