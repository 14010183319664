export class Formatters {
  static formatMoney (amount: number): string {
    return (
      amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ?? 'unavailable'
    )
  }

  // Function to format a number into a percentage format
  static formatPercentage (percentage: number): string {
    return `${percentage.toFixed(2)}%`
  }

  static formatCommas (num: number) {
    return num.toLocaleString()
  }

  static removeHyphensAndUppercase (input: string) {
    return input
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }
}
