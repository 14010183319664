import {
  Box,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  Chip,
  IconButton,
  Grid,
  Drawer,
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material'
import PageProps from '../_types/page-props.interface'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { StyleHelper } from '../components/style-helper'
import { useError } from '../context/error'
import { ReactElement, useEffect, useState } from 'react'
import { HeatersService } from '../services/heaters.service'
import { SectorHeater, Stock } from '../_types/sector-heaters.type'
import { Formatters } from '../_helpers/formatter.helper'
import LoadingTable from '../components/loading/table.loading'
import KnowledgeBtn from '../components/controls/knowledge-btn'
import { ArrowOutwardRounded, Close } from '@mui/icons-material'
import useBoolean from '../hooks/useBoolean'
import CarnivoreLogo from '../components/imgs/carnivore-logo'
import { StockService } from '../services/stock.service'
import { AlertService } from '../services/alert.service'
import { CarnivoreAlert } from '../_types/alert.type'
import { StockInfo } from '../_types/stock-info.type'
import StockPageLoadingState from '../components/loading/stock-page.loading'
import StockLogo from '../components/imgs/stock-logo'
import AlertView from '../components/alert-view'
import { useSearchParams } from 'react-router-dom'
import ViewAlertModal from '../components/modals/view-alert.modal'
import StockRow from '../components/stock-row'
import TickerLink from '../components/ticker-link'

function TickerWrapper({ children }: { children: ReactElement }) {
  return (
    <TableCell component='th' scope='row'>
      {children}
    </TableCell>
  )
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const _calculatePercentageChange = (
  initialValue: number,
  finalValue: number
): string => {
  const change = finalValue - initialValue
  return Formatters.formatPercentage((change / initialValue) * 100)
}

export default function HeatersPage(props: PageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  useDocumentTitle(props.title);
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const heatersService = new HeatersService(setError);
  const [heaters, setHeaters] = useState<SectorHeater[]>([]);
  const [ticker, setSelectedTicker] = useState<string>(searchParams.has('ticker') ? searchParams.get('ticker')! : '');
  const [tickerDrawerIsOpen, { toggle, setTrue }] = useBoolean(searchParams.has('ticker'));

  // State to handle sorting field and order for each heater
  const [sortConfig, setSortConfig] = useState<{ [key: string]: { field: keyof Stock; order: 'asc' | 'desc' } }>({});

  function handleTickerSelection(ticker: string) {
    setSearchParams({ ticker });
    setTrue();
    setSelectedTicker(ticker);
  }

  useEffect(() => {
    _getHeaters();
  }, []);

  const _getHeaters = async () => {
    setLoading(true);
    const res = await heatersService.getCurrentHeaters();
    setHeaters(res.heaters);
    setLoading(false);
  };

  const handleSort = (heaterId: string, field: any) => {
    setSortConfig((prev) => ({
      ...prev,
      [heaterId]: {
        field,
        order: prev[heaterId]?.order === 'asc' ? 'desc' : 'asc'
      }
    }));
  };

  const sortedStocks = (stocks: Stock[], heaterId: string) => {
    const { field, order } = sortConfig[heaterId] || {};
    if (!field) return stocks;

    return [...stocks].sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  };

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box display='flex' alignItems='center' gap={2}>
        <Typography variant='h4'>Sector Heaters</Typography>
        <KnowledgeBtn id='sector-heaters' />
      </Box>

      <StyleHelper.VerticalSpace />

      {loading && <LoadingTable rowCount={7} columnCount={5} />}

      {!loading &&
        heaters?.map((heater) => (
          <Box key={heater.id ?? ''} sx={{ mb: 4 }}>
            <Typography variant='h5'>{heater.title}</Typography>
            <StyleHelper.VerticalSpace />
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead
                  sx={{
                    '& th': {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText'
                    }
                  }}
                >
                  <TableRow>
                    <TableCell onClick={() => handleSort(heater.id ?? '', 'symbol')}>Symbol</TableCell>
                    <TableCell align='right' onClick={() => handleSort(heater.id ?? '', 'name')}>Name</TableCell>
                    <TableCell align='right' onClick={() => handleSort(heater.id ?? '', 'entryDate')}>Entry Date</TableCell>
                    <TableCell align='right' onClick={() => handleSort(heater.id ?? '', 'shares')}>Quantity</TableCell>
                    <TableCell align='right' onClick={() => handleSort(heater.id ?? '', 'entryPX')}>Entry PX</TableCell>
                    <TableCell align='right' onClick={() => handleSort(heater.id ?? '', 'currentPX')}>Current PX</TableCell>
                    <TableCell align='right' >Gain/Loss</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedStocks(heater.stocks, heater.id ?? '').map((stock) => (
                    <StyledTableRow key={stock.symbol}>
                      <TickerWrapper>
                        <TickerLink display='link' ticker={stock.symbol} />
                      </TickerWrapper>
                      <TableCell align='right'>{stock.name}</TableCell>
                      <TableCell align='right'>{new Date(stock.entryDate).toLocaleDateString('en-US')}</TableCell>
                      <TableCell align='right'>{stock.shares != null ? Formatters.formatCommas(stock.shares!) : "-"}</TableCell>
                      <TableCell align='right'>${Formatters.formatMoney(stock.entryPX)}</TableCell>
                      <TableCell align='right'>${Formatters.formatMoney(Number(stock.currentPX ?? 0))}</TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          color: stock.entryPX < (stock.currentPX ?? 0) ? 'success.main' : 'error.main'
                        }}
                      >
                        {_calculatePercentageChange(stock.entryPX, stock.currentPX ?? 0)}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      <StyleHelper.DoubleVerticalSpace />
    </Container>
  );
}
