import React, { useState, useEffect } from "react";
import { Modal, Box, CircularProgress, IconButton, Typography, TextField } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { ArrowBack, ArrowForward, ZoomIn, ZoomOut, Close, Download } from "@mui/icons-material";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { CarnivoreModalProps } from "../../../_types/modal-props.interface";
import { useError } from "../../../context/error";
import { ContentService } from "../../../services/content.service";

// Set the worker source for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

interface PDFModalProps extends CarnivoreModalProps {
    objectKey: string
}

function PdfModal({ objectKey, open, onClose }: PDFModalProps) {
    const [loading, setLoading] = useState(true);
    const [pdfLink, setPdfLink] = useState<string | null>(null);
    const { setError } = useError();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [zoom, setZoom] = useState(1.0);

    const contentService = new ContentService(setError);

    useEffect(() => {
        if (open) {
            setLoading(true);
            contentService.getLinkToContent(objectKey)
                .then((res) => {
                    setPdfLink(res.url);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [open, objectKey]);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setCurrentPage(1);
    };

    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => (numPages ? Math.min(prev + 1, numPages) : prev));
    const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.2, 3));
    const handleZoomOut = () => setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.5));

    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredPage = parseInt(event.target.value, 10);
        if (!isNaN(enteredPage) && enteredPage >= 1 && enteredPage <= (numPages || 1)) {
            setCurrentPage(enteredPage);
        } else if (event.target.value === "") {
            setCurrentPage(1); // Reset to page 1 if input is cleared
        }
    };

    const handlePageBlur = () => {
        if (currentPage < 1 || (numPages && currentPage > numPages)) {
            setCurrentPage(Math.min(Math.max(currentPage, 1), numPages || 1));
        }
    };

    const downloadFile = async () => {
        try {
            const response = await fetch(pdfLink ?? "");
            if (!response.ok) throw new Error("Failed to download file");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link to trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = "ebook.pdf";
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };


    return (
        <Modal open={open} onClose={onClose} aria-labelledby="pdf-modal" aria-describedby="pdf-modal-description">
            <Box
                sx={{
                    position: "absolute",
                    top: "10%",
                    left: "30%",
                    width: "40%",
                    height: "80vh",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 2 }}>
                    <Typography variant="h6"></Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>

                {loading ? (
                    <CircularProgress />
                ) : pdfLink ? (
                    <>
                        <Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton onClick={handleZoomOut}>
                                <ZoomOut />
                            </IconButton>
                            <IconButton onClick={handleZoomIn}>
                                <ZoomIn />
                            </IconButton>
                            <Typography variant="body2" sx={{ ml: 2 }}>
                                Page
                            </Typography>
                            <TextField
                                type="number"
                                value={currentPage}
                                onChange={handlePageChange}
                                onBlur={handlePageBlur}
                                inputProps={{
                                    min: 1,
                                    max: numPages || 1,
                                    style: { textAlign: 'center', width: '50px' }
                                }}
                                size="small"
                            />
                            <Typography variant="body2">of {numPages}</Typography>
                            <IconButton onClick={handlePreviousPage} disabled={currentPage <= 1}>
                                <ArrowBack />
                            </IconButton>
                            <IconButton onClick={handleNextPage} disabled={currentPage >= (numPages || 1)}>
                                <ArrowForward />
                            </IconButton>
                            {pdfLink && (
                                <IconButton
                                    onClick={downloadFile}
                                >
                                    <Download />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 1, overflow: "auto", width: "100%", textAlign: "center" }}>
                            <Document
                                file={pdfLink}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={(error) => console.error("Failed to load PDF:", error)}
                            >
                                <Page pageNumber={currentPage} scale={zoom} width={Math.min(800, window.innerWidth * 0.8)} />
                            </Document>
                        </Box>
                    </>
                ) : (
                    <Typography color="error">Failed to load PDF</Typography>
                )}
            </Box>
        </Modal>
    );
}

export default PdfModal;
