import { useEffect, useRef, useState } from 'react'
import { DailyWrap } from '../../_types/daily-wrap.type'
// import { useDispatch } from 'react-redux'
import { WrapService } from '../../services/wrap.service'
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser'
import FullScreenImg from '../../components/fullscreen-img'
import { FaFire } from 'react-icons/fa'
import PageProps from '../../_types/page-props.interface'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { useError } from '../../context/error'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import BackButton from '../../components/controls/back-btn'
import { StyleHelper } from '../../components/style-helper'
import CarnivoreBox from '../../components/carnivore-box'
import SP500Img from '../../components/imgs/sp500-img'
import NASDAQImg from '../../components/imgs/nasdaq-img'
import SectorCard from '../../components/sector-card'
import {
  ArrowRightAltRounded,
  ChatRounded,
  Fullscreen,
  GraphicEqOutlined,
  LocalFireDepartment,
  QueryStats
} from '@mui/icons-material'
import IFrameHTMLContent from '../../components/iframehtmlcontent'
import WrapNavigation from '../../components/wrap-nav'
import WRAPNAVLINKS from '../../_constants/wrap-nav.constants'
import ScrollToTopButton from '../../components/scroll-to-top'
import TickerLink from '../../components/ticker-link'

const PLACE_HOLD_IMG = 'https://placehold.co/600x400'

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

interface ViewWrapPageParams {
  id?: string
}

const options: HTMLReactParserOptions = {
  replace: domNode => {
    const typedDomNode = domNode as Element

    if (typedDomNode.name === 'img') {
      return <FullScreenImg imgSrc={typedDomNode.attribs.src} />
    }

    return false
  }
}

export default function ViewWrapPage (props: PageProps) {
  useDocumentTitle(props.title)
  const [wrap, setWrap] = useState<DailyWrap | null>()
  const [loading, setLoading] = useState(true)
  const { setError } = useError()

  const wrapService = new WrapService(setError)
  const [queryParams, setQueryParams] = useQueryParamsV2<ViewWrapPageParams>()

  const [selectedPosition, setSelectedPosition] = useState(0)
  useEffect(() => {
    _loadWrap()
  }, [])
  const _loadWrap = async () => {
    const id = queryParams.id
    if (!id) {
      setLoading(false)
      setError('No Wrap Specified')
      return
    }

    const resp = await wrapService.getWrapById(id)
    if (!resp.wrap) {
      setError('Could not find wrap with id' + id)
    } else {
      setWrap(resp.wrap)
    }

    setLoading(false)
  }

  const _wrapImages = (html: string) => {
    // Regular expression to find image tags in HTML
    const imgRegex = /<img.*?src="(.*?)".*?>/g

    // Replace image tags with FullScreenImg component
    const wrappedHtml = html.replace(imgRegex, (match, src) => {
      return `<img width="100%" src="${src}" />`
    })

    return wrappedHtml
  }

  const _calculatePercentage = (current: number, previous: number) => {
    const change = ((current - previous) / Math.abs(previous)) * 100
    const formattedChange = change.toFixed(2)
    const color = change > 0 ? 'success.main' : 'error.main'

    return (
      <Typography variant='h5' sx={{ pl: 1 }} color={color} display={'inline'}>
        {formattedChange}%
      </Typography>
    )
  }

  const _formatPercentage = (percent: number) => {
    let p = percent.toFixed(2)
    return (
      <Typography
        variant='h6'
        color={percent > 0 ? 'success.main' : 'error.main'}
      >
        {p}%
      </Typography>
    )
  }

  function formatDisplay (date: string) {
    let time

    // Try parsing the date as YYYY-MM-DD
    const isoFormat = new Date(date + 'T00:00:00')
    if (!isNaN(isoFormat.getTime())) {
      time = isoFormat
    } else {
      // Try parsing the date as MM-DD-YYYY
      const usFormat = new Date(date)
      if (!isNaN(usFormat.getTime())) {
        time = usFormat
      } else {
        throw new Error('Invalid date format')
      }
    }

    return time.toLocaleDateString('en-US', { dateStyle: 'full' })
  }

  const _header = (title: string, id: string) => {
    return (
      <Typography
        variant='h5'
        id={id}
        color={'primary'}
        fontWeight={'bold'}
        textTransform={'uppercase'}
      >
        {title}
      </Typography>
    )
  }

  const IframeComponent: React.FC<{ htmlContent: string }> = ({
    htmlContent
  }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    
    useEffect(() => {
      if (iframeRef.current) {
        const iframeDoc =
          iframeRef.current.contentDocument ||
          iframeRef.current.contentWindow?.document
        if (iframeDoc) {
          iframeDoc.open()
          iframeDoc.write(htmlContent)

          iframeDoc.close() 
        }
        const iFrame : any = document.getElementById('wrap-content-frame')
        if(iFrame){
          iFrame.style.height = iFrame?.contentWindow.document.documentElement.scrollHeight + 'px';
        }
      }
    }, [htmlContent])

    return (
      <iframe
      id={"wrap-content-frame"}
        ref={iframeRef}
        style={{ width: '100%',overflow : "hidden", border: 'none' }}
        title='Content Frame'
      />
    )
  }

  return (
    <Container maxWidth='xl' sx={{ pb: 4 }}>
      {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
      {wrap && wrap?.type === 'legacy' && (
        <>
          <BackButton fallbackUrl='/daily-wrap' />
          <StyleHelper.VerticalSpace />
          <CarnivoreBox title={formatDisplay(wrap?.formattedDate ?? '')}>
            <IframeComponent htmlContent={wrap?.legacyContentHTML ?? ''} />
          </CarnivoreBox>
        </>
      )}
      {wrap && wrap?.type !== 'legacy' && (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              lg={2}
              sx={{ position: 'sticky', top: 40, height: 'fit-content' }}
            >
              <CarnivoreBox title='Navigation'>
                <WrapNavigation items={WRAPNAVLINKS} />
              </CarnivoreBox>
            </Grid>
            <Grid item xs={12} lg={10}>
              <BackButton fallbackUrl='/daily-wrap' />
              <StyleHelper.VerticalSpace />
              <Typography variant='h2'>
                {formatDisplay(wrap.formattedDate!)}
              </Typography>
              <Typography variant='subtitle2'>
                Last Updated {new Date(wrap.updatedAt!).toLocaleString('en-US')}
              </Typography>
              <Divider />
              <StyleHelper.DoubleVerticalSpace />
              <CarnivoreBox title='Welcome'>
                <Typography variant='body1'>
                  Welcome to our Daily Wrap. Carnivore is an old school trading
                  desk operation. We are not politically correct. We don't take
                  in people's money. We only manage our own. We're not trying to
                  sell you anything. Except maybe a path to your own True
                  Freedom. With our model portfolio below, you can see what we
                  are all about.
                </Typography>
              </CarnivoreBox>

              <StyleHelper.VerticalSpace />

              {_header('overview', 'wrap-overview')}
              <Divider />
              <StyleHelper.VerticalSpace />

              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <CarnivoreBox title='YTD Performance'>
                    {_formatPercentage(wrap.currentYTD ?? 0)}
                  </CarnivoreBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <CarnivoreBox title='New Positions'>
                    <Typography variant='h5'>
                      {wrap.positions?.filter(p => p.newPosition)?.length ?? 0}
                    </Typography>
                  </CarnivoreBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <CarnivoreBox title='Portfolio Open Value'>
                    <Typography variant='h5'>
                      {moneyFormatter.format(wrap.startValue ?? 0)}
                    </Typography>
                  </CarnivoreBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <CarnivoreBox title='Portfolio Close Value'>
                    <Typography variant='h5'>
                      {moneyFormatter.format(wrap.endValue ?? 0)}
                      {/* {_calculatePercentage(
                        wrap.endValue ?? 0,
                        wrap.startValue ?? 0
                      )} */}
                    </Typography>
                  </CarnivoreBox>
                </Grid>
              </Grid>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='YTD Performance'>
                <FullScreenImg imgSrc={wrap.images?.performanceSrc ?? ''} />
              </CarnivoreBox>

              <StyleHelper.DoubleVerticalSpace />
              {_header('Market Overview', '')}
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Market Headlines' id='wrap-headlines'>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      wrap.contentBlocks?.marketHeadlines ?? '<p>None today</p>'
                  }}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />

              <CarnivoreBox title='Index Performance' id='wrap-index'>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Box
                      width={'40%'}
                      display={'flex'}
                      justifyContent={'center'}
                      height={200}
                    >
                      <SP500Img />
                    </Box>
                    <Typography variant='h4' fontWeight={'bold'}>
                      {wrap.dowPoints}
                    </Typography>
                    <Typography variant='caption'>Points Today</Typography>
                    <StyleHelper.VerticalSpace />
                    <FullScreenImg imgSrc={wrap.images?.spxIndexSrc ?? ''} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Box
                      width={'40%'}
                      display={'flex'}
                      justifyContent={'center'}
                      height={'200px'}
                    >
                      <NASDAQImg />
                    </Box>
                    <Typography variant='h4' fontWeight={'bold'}>
                      {wrap.nasdaqPoints}
                    </Typography>
                    <Typography variant='caption'>Points Today</Typography>
                    <StyleHelper.VerticalSpace />
                    <FullScreenImg imgSrc={wrap.images?.compqIndexSrc ?? ''} />
                  </Grid>
                </Grid>
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />

              <CarnivoreBox title='Index Commentary'>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      wrap.contentBlocks?.indexBreakdown ?? '<p>None today</p>'
                  }}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Sector Performance' id='wrap-sectors'>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => window.open('/sector-heaters', '_blank')}
                    endIcon={<ArrowRightAltRounded />}
                  >
                    View Current Heaters
                  </Button>
                </Box>

                <StyleHelper.VerticalSpace />
                <Grid container flexWrap={'wrap'} spacing={2}>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Technology'
                      content={wrap.sectors?.technology}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard title='Energy' content={wrap.sectors?.energy} />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Industrials'
                      content={wrap.sectors?.industrials}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Materials'
                      content={wrap.sectors?.materials}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Real Estate'
                      content={wrap.sectors?.realEstate}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Health Care'
                      content={wrap.sectors?.healthCare}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Financial'
                      content={wrap.sectors?.financial}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Communication'
                      content={wrap.sectors?.communication}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Utilities'
                      content={wrap.sectors?.utilities}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Consumer Discretionary'
                      content={wrap.sectors?.consumerDiscretionary}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <SectorCard
                      title='Consumer Staples'
                      content={wrap.sectors?.consumerStaples}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} key={wrap.id}>
                    <Paper elevation={1}>
                      <Box p={3}>
                        <Typography
                          variant='subtitle1'
                          fontWeight={'bold'}
                          textAlign={'center'}
                        >
                          Heat Map
                        </Typography>
                        <Divider />
                        <StyleHelper.VerticalSpace />

                        <FullScreenImg imgSrc={wrap.images?.heatMapSrc ?? ''} />
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Sector Breakdown'>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      wrap.contentBlocks?.indexBreakdown ?? '<p>None today</p>'
                  }}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              {_header('Portfolio Overview', 'wrap-portfolio')}
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Swing Trades'>
                <FullScreenImg
                  imgSrc={wrap.images?.modelPortfolioSwingSrc ?? ''}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Portfolio Performance' id='wrap-performance'>
                <FullScreenImg
                  imgSrc={wrap.images?.portfolioPerformanceSrc ?? ''}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Percent of Portfolio'>
                <FullScreenImg
                  imgSrc={wrap.images?.percentPortfolioSrc ?? ''}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Specialty Trades'>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      wrap.contentBlocks?.specialtyTrades ?? '<p>None today</p>'
                  }}
                />
              </CarnivoreBox>
              <StyleHelper.VerticalSpace />

              <CarnivoreBox title='Positions' id='wrap-positions'>
                <Box>
                  <Tabs
                    value={selectedPosition}
                    onChange={(event: React.SyntheticEvent, newValue: number) =>
                      setSelectedPosition(newValue)
                    }
                    aria-label='basic tabs example'
                  >
                    {wrap.positions?.map(p => (
                      <Tab
                        iconPosition='start'
                        icon={
                          p.newPosition ? <LocalFireDepartment /> : undefined
                        }
                        key={p.ticker}
                        label={p.ticker}
                        id={`position-tab-${p.ticker}`}
                      />
                    ))}
                  </Tabs>

                  {wrap.positions?.map((p, index) => (
                    <Box
                      role='tabpanel'
                      hidden={selectedPosition !== index}
                      id={`simple-tabpanel-${index}`}
                      aria-labelledby={`simple-tab-${index}`}
                    >
                      {selectedPosition === index && (
                        <Box p={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={8}>
                              <Box display='flex' justifyContent={'flex-start'}>
                                <Typography variant='h4'>{p.ticker}</Typography>
                                <TickerLink display='chip' ticker={p.ticker!} />
                                {/* <Chip
                                  sx={{
                                    ml: 2
                                  }}
                                  clickable
                                  onClick={() =>
                                    window.open(`/stock/${p.ticker}`, '_blank')
                                  }
                                  label={`View ${p.ticker}`}
                                  variant='filled'
                                  color='primary'
                                  icon={<QueryStats />}
                                /> */}
                              </Box>
                              <Typography variant='body1'>
                                <strong>Current Shares: </strong>
                                {Number(p.shares ?? 0).toLocaleString()}{' '}
                              </Typography>
                              {p.newPosition && (
                                <>
                                  <StyleHelper.VerticalSpace />
                                  <Chip
                                    color='primary'
                                    variant='outlined'
                                    label='New Position'
                                    icon={<LocalFireDepartment />}
                                  />
                                </>
                              )}
                              <StyleHelper.VerticalSpace />
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                fontWeight={'bold'}
                              >
                                Commentary
                              </Typography>
                              <Typography variant='body1'>
                                {p.commentary}
                              </Typography>
                              <StyleHelper.VerticalSpace />
                              <Divider />
                              <StyleHelper.VerticalSpace />

                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                fontWeight={'bold'}
                              >
                                About
                              </Typography>

                              <Typography variant='body1'>{p.descr}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <FullScreenImg imgSrc={p.chartSrc ?? ''} />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </CarnivoreBox>

              <StyleHelper.VerticalSpace />
              {_header('Commentary', 'wrap-commentary')}
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title="Trader Z's Thoughts" id='wrap-traderz'>
                <div>
                  {parse(wrap.contentBlocks?.traderZThoughts ?? '', options)}
                </div>
              </CarnivoreBox>

              <StyleHelper.VerticalSpace />
              <CarnivoreBox title="Dutch's Commentary" id='wrap-dutch'>
                <IFrameHTMLContent
                  content={
                    wrap.contentBlocks?.commentary ?? '<p>None today</p>'
                  }
                />
              </CarnivoreBox>

              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Bonus Section'>
                <IFrameHTMLContent
                  content={wrap.contentBlocks?.bonus ?? '<p>None today</p>'}
                />
              </CarnivoreBox>
              <StyleHelper.DoubleVerticalSpace />
              <StyleHelper.VerticalSpace />
              <CarnivoreBox title='Disclaimer'>
                <Typography variant='caption'>
                  WE DO NOT GIVE FINANCIAL ADVICE. WE ARE NOT FINANCIAL
                  ADVISORS. NOTHING HEREIN IS TO BE CONSIDERED A RECOMMENDATION.
                  ALWAYS CONSULT WITH YOUR OWN FINANCIAL INSTITUTION FOR YOUR
                  SPECIFIC SITUATION. THIS SITE IS FOR ENTERTAINMENT AND
                  INFORMATIONAL PURPOSES ONLY. OUR COMMENTS AND THE INFORMATION
                  IN THE COMMENTARY SECTION IN PARTICULAR ARE PURELY OUR
                  OPINIONS AND ARE NOT INTENDED TO BE RELIED UPON TO MAKE
                  FINANCIAL DECISIONS. OUR POLICY ALWAYS HAS BEEN THAT IF YOU
                  ARE NOT HAPPY WITH OUR SERVICE YOU CAN REQUEST A REFUND. WE
                  MAKE NO GUARANTEES ON PERFORMANCE AND CAN’T DELIVER
                  INDIVIDUALIZED ADVICE. WE DO NOT MANAGE YOUR MONEY OR KNOW
                  YOUR PERSONAL SITUATION – BUT IF YOU ARE UNHAPPY WITH OUR
                  SERVICE, LET US KNOW. WE ALWAYS GO THE EXTRA MILE TO TAKE CARE
                  OF THE CARNIVORE FAMILY. CARNIVORE TRADING, LLC – IMPORTANT
                  NOTICE Carnivore is not a registered investment adviser and
                  operates legally pursuant to an exemption to registration for
                  business and financial publications. Nothing in these written
                  materials and nothing we say to you should be taken as
                  personalized investment, legal, accounting or tax advice, or a
                  recommendation to buy, hold, sell a security. You should
                  consult a licensed securities professional before making any
                  investment decisions. Past performance is no guarantee of
                  future results. Inherent in any investment is the potential
                  for loss. It should not be assumed that any investment in
                  securities, companies, sectors or markets identified and
                  described or discussed herein will be or were profitable. All
                  information is current as of the date of herein and is subject
                  to change without notice. The Carnivore Trading Portfolio is a
                  hypothetical portfolio that is weighted and may use leverage
                  in large quantities. These are not the returns of an actual
                  fund or portfolio of stocks. Your results may vary
                  significantly from the Carnivore Trading Portfolio due to many
                  factors outside of our control. You understand and agree that
                  Carnivore is not directing you as to what to buy or when or in
                  what amounts, and that those decisions are yours and yours
                  alone (along with any licensed securities professional you may
                  engage) as are your results. You understand that investing in
                  the stock market carries risk of loss and that in no manner
                  have we led you to believe otherwise. *** Notes on How We
                  Calculate Past Performance ***The Carnivore Portfolio is a
                  hypothetical and weighted portfolio. When we say “buy” it is a
                  hypothetical purchase, an addition to the hypothetical
                  portfolio. The Carnivore Portfolio uses leverage from time to
                  time in large amounts and weightings can change rapidly and
                  the positions may change rapidly as well. We rarely day trade.
                  We do not trade options. Our picks are the same as those we
                  use with all Carnivores in the text alerts. We don’t buy or
                  sell anything differently than what you see in the Instant
                  Text Alerts. Our weightings in each recommendation may change
                  rapidly and they can be very, very concentrated. At times we
                  may also hold a large cash position and little or no stocks or
                  other securities at all. When we say we are buying a stock, we
                  are adding it to the portfolio. When we say we are selling a
                  stock, we are taking it out of the portfolio. We never take
                  any compensation from anyone for mentioning a stock. The only
                  money we make is from the monthly fees from our subscribers.
                  Therefore, our loyalty is clearly to our subscribers at all
                  times. You will never see an advertisement for a fund or a
                  company shilling its stock on our site. As the portfolio is
                  hypothetical there can be no “front-running” of our
                  subscribers. We analyze almost exclusively only high-quality
                  stocks with a lot of liquidity and that are traded on major
                  stock exchanges, maintain current filings with the SEC, and
                  are mostly all followed by research on Wall Street, so there
                  is ample available information out in the marketplace for our
                  subscribers to research anything that we are discussing. We do
                  NOT analyze options trades, analyze micro-cap stocks, penny
                  stocks, or other “low quality” or esoteric securities or
                  stocks. Carnivore Trading LLC is not an advisor or manager of
                  other people’s money and we DO NOT MAKE RECOMMENDATIONS OR
                  OFFER ADVICE. Your results may differ materially from those
                  expressed or utilized by Carnivore Trading LLC due to any
                  number of factors. As a provider of education and
                  entertainment we do not have access to the personal trading
                  accounts or brokerage statements of our subscribers and in no
                  way have any control over what any of our subscribers buy or
                  sell. If you do not agree with any term or provision of our
                  Terms and Conditions you should not use our site. Please be
                  advised that your continued use of the site, services,
                  content, or information shall indicate your consent and
                  agreement to our Terms and Conditions. IMPORTANT – PLEASE READ
                  WE ANALYZE QUALITY, LIQUID, WELL FOLLOWED AND CURRENTLY
                  REPORTING STOCKS ON MAJOR STOCK EXCHANGES. WE PRIDE OURSELVES
                  ON BEING A SERVICE THAT ANY QUALITY INSTITUTION CAN UTILIZE IN
                  ITS OWN WAY. WE MAKE NO RECOMMENDATIONS, WE ONLY SHARE WITH
                  YOU OUR OPINIONS, OUR THOUGHTS, AND WHAT WE ARE SEEING IN THE
                  MARKETS. Content, news, research, tools, and securities
                  symbols are for educational and illustrative purposes only and
                  do not imply a recommendation or solicitation to buy or sell a
                  particular security or to engage in any particular investment
                  strategy. Companies that are not affiliated with us may supply
                  some of the content. The source of all, third-party Content,
                  in which Carnivore Trading LLC receives some sort of
                  compensation, is clearly and prominently identified herein as
                  “Sponsored by” or “Sponsored”. We have not been involved in
                  the preparation, adoption or editing of third party content
                  and we do not endorse or approve such content. The information
                  provided is not warranted as to completeness or accuracy and
                  is subject to change without notice. The projections or other
                  information regarding the likelihood of various investment
                  outcomes are hypothetical in nature, are not guaranteed for
                  accuracy or completeness, do not reflect actual investment
                  results and are not guarantees of future results. All
                  investments involve risk, losses may exceed the principal
                  invested, and the past performance of a security, industry,
                  sector, market, or financial product does not guarantee future
                  results or returns. Before investing in a fund or ETF or
                  stock, consider the fund’s investment objectives, risks,
                  charges, and expenses and your own investment tolerances and
                  objectives and consult with a licensed financial professional
                  although they may be dumber than a stump, so beware of that,
                  too.
                </Typography>
              </CarnivoreBox>
              <StyleHelper.DoubleVerticalSpace />
            </Grid>
          </Grid>
          <ScrollToTopButton />
        </>
      )}
    </Container>
  )
}
