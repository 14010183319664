import { Box, Button, Grid, Typography } from "@mui/material";
import { StyleHelper } from "../style-helper";
import { ArrowRightAltRounded } from "@mui/icons-material";
import { useState } from "react";
import PdfModal from "../modals/content/pdf-reader.modal";
import CarnivoreBox from "../carnivore-box";

export default function EbookEducation() {

    const [showModal, setShowModal] = useState(false)
    return <Box p={2}>
        <CarnivoreBox>

            <Grid container spacing={2}>
                <Grid item md={3} xs={12}  >
                    <img src={require('../../assets/imgs/content/e-book.jpg')} alt="e-book cover photo" style={{
                        width: '100%', 
                    }} />
                </Grid>
                <Grid item md={7} xs={12}  >
                    <Typography variant="h5" fontWeight={'bold'}>Lions of Wall Street: Make 100% Every Year Trading Stocks the Carnivore Way</Typography>
                    <StyleHelper.VerticalSpace />
                    <Typography variant="caption" >By Jay, Dutch, & Trader Z</Typography>
                    <StyleHelper.DoubleVerticalSpace />
                    <Typography variant="body1" gutterBottom>
                        In this book, we will expose the inherent flaws of the Wall Street establishment, and how to do it differently. This book will offer you profound tools of the trade and incredible market insights you won't get anywhere else. You'll walk away more empowered and capable of becoming a Hall of Fame trader. Join us in discovering how to become a Lion of Wall Street, the ruler of your own domain, and achieve true financial freedom.
                    </Typography>
                    <StyleHelper.DoubleVerticalSpace />

                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => { setShowModal(true) }}

                        endIcon={<ArrowRightAltRounded />}
                    >
                        Read Now
                    </Button>

                    <PdfModal open={showModal} onClose={() => setShowModal(false)} objectKey="pdfs/Lions-of-Wall-Street.pdf" size="lg" />

                </Grid>
            </Grid>
        </CarnivoreBox>

    </Box>

}