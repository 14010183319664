import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useGlobalState } from './global'
import { useError } from './error'
import { UserService } from '../services/user.service'

interface WebSocketContextProps {
  ws: WebSocket | null
}

const WebSocketContext = createContext<WebSocketContextProps>({ ws: null })

export const useWebSocket = () => {
  return useContext(WebSocketContext)
}

interface WebSocketProviderProps {
  children: React.ReactNode
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children
}) => {
  const [ws, setWs] = useState<WebSocket | null>(null)
  const wsRef = useRef<WebSocket | null>(null)

  const initializedRef = useRef<boolean>(false)
  const { user, setLiveAlert } = useGlobalState()
  const { setError } = useError()
  const userService = new UserService(setError)
  const interval = useRef<NodeJS.Timer | null>()
  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true
      _setupWS()
    }
  }, [user])

  const _setupWS = async () => {
    const res = await userService.getWebsocketToken()
    if (res.token) {
      const socket = new WebSocket(
        `${process.env.REACT_APP_WSS}?token=${res.token}`
      )
      wsRef.current = socket
      setWs(socket)

      socket.onopen = () => {
        console.log('WebSocket connection established')
      }

      socket.onmessage = event => {
        console.log(`Received message: ${event.data}`)
        try {
          const wsMessage = JSON.parse(event.data)
          if (wsMessage.type === 'new-alert') {
            setLiveAlert(wsMessage?.payload?.alert)
            setTimeout(() => {
              setLiveAlert(null)
            }, 10000)
            const title = document.title
            let count = 0
            const interval = setInterval(() => {
              count++
              if (count === 6) {
                clearInterval(interval)
                document.title = title
              } else {
                if (count % 2 === 0) {
                  document.title = title
                } else {
                  document.title = `New Alert`
                }
              }
            }, 1500)
          }
        } catch (e) {}
      }

      socket.onclose = () => {
        console.log('WebSocket connection closed')
        setWs(null)
        if (res.token) {
          if (interval.current) {
            clearInterval(interval.current)
          }
          setTimeout(() => {
            _setupWS()
          }, 10000)
        }
      }

      socket.onerror = error => {
        console.error(`WebSocket error: ${error}`)
        if (res.token) {
          if (interval.current) {
            clearInterval(interval.current)
          }
          setTimeout(() => {
            _setupWS()
          }, 10000)
        }
      }

      interval.current = setInterval(() => {
        try {
          socket.send(JSON.stringify({ type: 'ping' }))
        } catch (e) {
          if (interval.current) {
            clearInterval(interval.current)
          }
          setTimeout(() => {
            _setupWS()
          }, 10000)
        }
      }, 60 * 1000)
    }
  }

  return (
    <WebSocketContext.Provider value={{ ws }}>
      {children}
    </WebSocketContext.Provider>
  )
}
